// Libraries
import styled from "styled-components";
import { breakpoints } from "../../../utils/breakpoints";

export const SXForumCategoryListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 2rem;
`;

export const SXSpinner = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15rem;
`;
