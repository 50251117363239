// Libraries
import styled from "styled-components";
import { variant } from "styled-system";

// Packages
import { InspiColors } from "@inspigoid/inspids-react";

// breakpoints
import { breakpoints } from "../../utils/breakpoints";

export const SXAdditional = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const SXContent = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

export const SXContentImage = styled.div`
  display: flex;
  align-items: center;

  ${variant({
    prop: "size",
    variants: {
      small: {
        width: "1.6rem",
        height: "1.6rem",
      },
      medium: {
        width: "2.4rem",
        height: "2.4rem",
      },
      large: {
        width: "3.2rem",
        height: "3.2rem",
      },
    },
  })}

  @media (max-width: ${breakpoints.mobileL}) {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const SXContentText = styled.div`
  ${variant({
    prop: "size",
    variants: {
      small: {
        fontSize: "1.1rem",
      },
      medium: {
        fontSize: "1.6rem",
      },
      large: {
        fontSize: "2rem",
      },
    },
  })};

  @media (max-width: ${breakpoints.mobileL}) {
    ${variant({
      prop: "size",
      variants: {
        small: {
          fontSize: "1.1rem",
        },
        medium: {
          fontSize: "1.3rem",
        },
        large: {
          fontSize: "1.6rem",
        },
      },
    })};
  }

  @media (max-width: ${breakpoints.tabletL}) {
    ${variant({
      prop: "size",
      variants: {
        small: {
          fontSize: "1rem",
        },
        medium: {
          fontSize: "1.2rem",
        },
        large: {
          fontSize: "1.3rem",
        },
      },
    })};
  }
`;

export const SXDotDivider = styled.div`
  width: 4px;
  height: 4px;
  background: #d9d9d9;
  border-radius: 2px;
`;
