import { apiActionType } from "../../types/apiType";
import { loginActionType } from "../../types/loginType";
import Request from "../../request";
import routes from "../../../utils/routes";
import { account } from "../../../utils/apiroutelist";

export const apiStart = (payload) => {
  return {
    type: apiActionType.API_START,
    payload: payload,
  };
};
export const apiEnd = (payload) => {
  return {
    type: apiActionType.API_STOP,
    payload: payload,
  };
};

export const apiError = (payload) => {
  return {
    type: apiActionType.API_ERROR,
    payload: payload,
  };
};

export const getAccount = (response) => {
  return {
    type: loginActionType.GET_ACCOUNT,
    payload: {
      status: response.status,
      data: response.data.data,
    },
  };
};

export const fetchAccount = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(apiStart({ message: "Mendapatkan AKun" }));

    try {
      const request = new Request(null, null, true);
      const response = {
        ...(await request.get(account.self())),
      };
      if (response.status === 200) {
        dispatch(getAccount(response));
        resolve();
      } else {
        const pathname = window.location.pathname;
        window.open(routes.signIn(pathname), "_self");
      }
    } catch (error) {
      throw error;
    }
  });
};
