// Libraries
import { useState } from "react";

// Types
import { NotificationType } from "../contexts/NotificationContext";

export const useNotification = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState(
    NotificationType.Success
  );

  const [message, setMessage] = useState("");

  const openNotification = (type, message) => {
    setNotificationType(type);
    setMessage(message);
    setShowNotification(true);

    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const notification = {
    show: showNotification,
    type: notificationType,
    message: message,
    open: openNotification,
  };

  return notification;
};
