import React, { Suspense, useEffect } from "react";
import { compose } from "redux";
import { MoonLoader } from "react-spinners";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Header from "../../../components/Header/Header";
import {
  SXDashboardLayout,
  SXMain,
  SXMainContainer,
  SXSpinner,
} from "./DashboardLayout.styled";

// wrappers
import withNotification from "../../wrappers/withNotification";

import { InspiColors } from "@inspigoid/inspids-react";
import Section from "../../../components/Section/Section";
import Footer from "../../../components/Footer/Footer";

const DashboardLayout = ({ children }) => {
  return (
    <SXDashboardLayout>
      <Header withBackButton={true} />
      <Suspense
        fallback={
          <SXSpinner>
            <MoonLoader size={40} color={InspiColors.JetBlack500} />
          </SXSpinner>
        }
      >
        <SXMain>
          <Section>
            <SXMainContainer>{children}</SXMainContainer>
          </Section>
        </SXMain>

        <Footer />
      </Suspense>
    </SXDashboardLayout>
  );
};

export default compose(withNotification)(DashboardLayout);
