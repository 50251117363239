import React from "react";

// Contexts
import NotificationContext from "../../contexts/NotificationContext";

// Hooks
import { useNotification } from "../../hooks/useNotification";

// Components
import { Notification } from "../../components/Notification/Notification";

const withNotification = (WrappedComponent) => {
  const EnhancedComponent = (props) => {
    const notification = useNotification();

    return (
      <NotificationContext.Provider value={notification}>
        <Notification />
        <WrappedComponent {...props} />
      </NotificationContext.Provider>
    );
  };

  return EnhancedComponent;
};

export default withNotification;
