import { BASEURL, SERVICE } from "./constant";

export const account = {
  self: () => SERVICE.ACCOUNT + "/accounts/self?ref_subs=true",
  revokeUser: () => SERVICE.ACCOUNT + "/login/revoke",
};

export const content = {
  forum: () => {
    return `${SERVICE.API}/forum`;
  },
  forumByPath: (path) => {
    return `${SERVICE.API}/forum/path/${path}`;
  },
  forumCategories: () => {
    return `${SERVICE.API}/tags`;
  },
  forumCategoryById: (id) => {
    return `${SERVICE.API}/tags/${id}`;
  },
  forumCategoryPosts: (forumId, tagId) => {
    return `${SERVICE.API}/${forumId}/tags/${tagId}/posts`;
  },
};

// post
export const createPost = () => `${SERVICE.API}/posts/create`;

export const likePost = (postId) => `${SERVICE.API}/tags/posts/like/${postId}`;

export const getPostById = (postId) => `${SERVICE.API}/tags/posts/${postId}`;

// reply
export const createReplyByPostId = (postId) =>
  `${SERVICE.API}/reply/create/${postId}`;
export const getReplyByPostId = (postId) => `${SERVICE.API}/reply/${postId}`;
