import React, { useState } from "react";
import { useSelector } from "react-redux";

// styled
import {
  SXAdditional,
  SXContent,
  SXContentImage,
  SXContentText,
  SXDotDivider,
} from "./Additional.styled";

// Packages
import { Text, InspiColors, Box } from "@inspigoid/inspids-react";

const Additional = ({
  likes,
  upvotes,
  comments,
  size = "small",
  onClickLike,
  onClickPost,
  isList,
}) => {
  const { user } = useSelector(({ rootReducer }) => rootReducer);
  const { id: userId } = user?.data;
  const checkLikeByUserId = user && upvotes && upvotes.includes(userId);
  const [clickedLike, setClickedLike] = useState(() => {
    if (!checkLikeByUserId) {
      return false;
    }
    return true;
  });

  const contents = [
    {
      icon: "/assets/icons/icon-likes.svg",
      text: likes || `${0} Disukai`,
    },
    {
      icon: "/assets/icons/icon-comment.svg",
      text: comments || `${0} Komentar`,
    },
  ];

  const handleOnClickLike = () => {
    setClickedLike(!clickedLike);
    onClickLike();
  };

  const handleOnClickPost = () => {
    onClickPost();
  };

  const componentsText = (c, index) => {
    switch (index) {
      case 0:
        return (
          <Box>
            <Text
              type="p"
              fontSize={"13px"}
              color={InspiColors.JetBlack200}
              fontWeight={300}
            >
              {c?.text}
            </Text>
          </Box>
        );
      case 1:
        return (
          <Text
            type="p"
            fontSize={"13px"}
            color={InspiColors.JetBlack200}
            fontWeight={300}
            style={isList ? { cursor: "pointer" } : { cursor: "default" }}
            onClick={() => {
              if (!isList) return;
              handleOnClickPost();
            }}
          >
            {c?.text}
          </Text>
        );
    }
  };

  const componentsIcon = (c, index) => {
    switch (index) {
      case 0:
        if (clickedLike) {
          return (
            <img
              style={{
                cursor: "pointer",
              }}
              onClick={handleOnClickLike}
              src={"/assets/icons/icon-likes-full.svg"}
              alt=""
              width={14}
              height={14}
            />
          );
        }
        return (
          <img
            style={{
              cursor: "pointer",
            }}
            onClick={handleOnClickLike}
            src={c?.icon}
            alt=""
            width={14}
            height={14}
          />
        );
      case 1:
        return (
          <img
            src={c?.icon}
            alt=""
            width={14}
            height={14}
            style={isList ? { cursor: "pointer" } : { cursor: "default" }}
            onClick={() => {
              if (!isList) return;
              handleOnClickPost();
            }}
          />
        );

      default:
        return <img src={c?.icon} alt="" width={14} height={14} />;
    }
  };

  return (
    <SXAdditional>
      {contents.map((c, i) => {
        return (
          <SXContent key={i} size={size}>
            {i !== 0 && <SXDotDivider />}

            {c?.icon && (
              <SXContentImage size={size}>
                {componentsIcon(c, i)}
              </SXContentImage>
            )}

            <SXContentText size={size}>{componentsText(c, i)}</SXContentText>
          </SXContent>
        );
      })}
    </SXAdditional>
  );
};

export default Additional;
