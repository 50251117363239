// Libraries
import styled from "styled-components";
import { breakpoints } from "../../../utils/breakpoints";

export const SXForumCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const SXBoxImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;

  img {
    max-width: 104px;
  }

  @media (max-width: ${breakpoints.mobileL}) {
    max-width: 80px;
  }
`;

export const SXBoxTitle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 48px;

  @media (max-width: ${breakpoints.mobileL}) {
    p {
      max-width: 200px;
      width: 17rem;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
`;
