import Request from "../../request";
import { forumCategoriesActionType } from "../../types/forumCategorisType";
import { content } from "../../../utils/apiroutelist";
import { apiStart, apiEnd } from "../account/_login";

export const getForumCategories = (response) => {
  return {
    type: forumCategoriesActionType.GET_FORUM_CATEGORIES,
    payload: {
      loading: false,
      status: response.status,
      list: response.data.data,
    },
  };
};

export const getForumCategoryById = (response) => {
  return {
    type: forumCategoriesActionType.GET_FORUM_CATEGORY_BY_ID,
    payload: {
      loading: false,
      status: response.status,
      detail: response.data.data,
    },
  };
};

export const clearForumCategory = () => {
  return {
    type: forumCategoriesActionType.CLEAR_FORUM_CATEGORIES,
    payload: {
      loading: true,
      status: null,
    },
  };
};

export const fetchForumCategories = () => async (dispatch) => {
  dispatch(apiStart());
  try {
    const request = new Request(null, null, true);
    const response = await request.get(content.forumCategories());
    dispatch(getForumCategories(response));
  } catch (error) {
    throw error;
  } finally {
    dispatch(apiEnd());
  }
};

export const fetchForumCategoryById = (id) => async (dispatch) => {
  dispatch(apiStart());
  try {
    const request = new Request(null, null, true);
    const response = await request.get(content.forumCategoryById(id));
    dispatch(getForumCategoryById(response));
  } catch (error) {
    throw error;
  } finally {
    dispatch(apiEnd());
  }
};
