// Libraries
import styled from "styled-components";

// Packages
import { InspiColors } from "@inspigoid/inspids-react";

// breakpoinst
import { breakpoints } from "../../../utils/breakpoints";

export const SXDialogPanel = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 60rem;
  height: auto;
  max-height: 98vh;
  overflow-y: auto;
  padding: 3.2rem;
  padding-bottom: 0;
  background-color: white;
  border: 1px solid ${InspiColors.JetBlack50};
  border-radius: 20px;
  z-index: 999999;

  &::-webkit-scrollbar {
    width: 0;
  }

  @media (max-width: ${breakpoints.mobileL}) {
    width: 100%;
    top: unset;
    bottom: 0;
    max-height: unset;
    transform: translate(-50%, 0);
    padding: 2.5rem 2rem;
    padding-bottom: 3.2rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const SXHeader = styled.div`
  padding-bottom: 1rem;
  border-bottom: 1px solid ${InspiColors.JetBlack50};
`;

export const SXHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 0.2rem;
`;

export const SXTitleIcon = styled.div`
  display: flex;
  width: 2.4rem;
  height: 2.4rem;
`;

export const SXTitleText = styled.div``;

export const SXTitleClose = styled.div`
  margin-left: auto;
  display: flex;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
`;

export const SXBody = styled.div``;
