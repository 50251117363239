import React from "react";
import { Box, Text, InspiColors } from "@inspigoid/inspids-react";

// Styled
import {
  SXForumCategoryContainer,
  SXBoxImage,
  SXBoxTitle,
} from "./ForumCategory.styled";

const ForumCategory = (props) => {
  const { id, forumCategoryName, forumCategoryImg, onClick } = props;
  return (
    <SXForumCategoryContainer onClick={() => onClick(id)}>
      <SXBoxImage>
        <img src={forumCategoryImg} alt={forumCategoryName} />
      </SXBoxImage>
      <SXBoxTitle>
        <Text
          type="p"
          color={InspiColors.JetBlack500}
          fontSize={{ mobileS: 13, tabletM: 16 }}
          fontWeight={700}
          textAlign={"center"}
        >
          {forumCategoryName}
        </Text>
      </SXBoxTitle>
    </SXForumCategoryContainer>
  );
};

export default ForumCategory;
