import styled from "styled-components";
import { breakpoints } from "../../utils/breakpoints";

export const SXNoContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SXContainer = styled.div`
  max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SXImage = styled.img`
  width: 300px;
  height: 300px;

  @media (max-width: ${breakpoints.mobileL}) {
    width: 200px;
    height: 200px;
  }
`;
