import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";
import { Dialog } from "@headlessui/react";
import Lottie from "lottie-react";

// Packages
import { Box, InspiColors, Text, Button } from "@inspigoid/inspids-react";

// Components
import Overlay from "../Overlay/Overlay";

// styled
import {
  SXDialogPanel,
  SXHeader,
  SXHeaderDecoration,
  SXHeaderLottie,
  SXBody,
  SXDescription,
  SXDescriptionContent,
} from "./RulesModal.styled";

// lottie
import rules from "./Lottie/rules.json";

const RulesModal = ({ isOpen, onClose = () => {} }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const modalContent = isOpen ? (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Panel>
        <SXDialogPanel>
          <SXHeader>
            <SXHeaderDecoration>
              <img src="/assets/modal/decorations/flower.svg" alt="" />
            </SXHeaderDecoration>

            <SXHeaderLottie>
              <Lottie animationData={rules} loop={true} />
            </SXHeaderLottie>
          </SXHeader>

          <SXBody>
            <Box mb={10} style={{ textAlign: "center" }}>
              <Text
                type="p"
                textAlign="center"
                fontWeight={700}
                fontSize={{ mobileS: 16, tabletM: 20 }}
              >
                Please read these rules before posting
              </Text>
            </Box>

            {/* Description */}
            <SXDescription>
              <SXDescriptionContent>
                <Box>
                  <img src="/assets/icons/bullet.svg" />
                </Box>
                <Box>
                  <Text
                    type="p"
                    fontWeight={400}
                    fontSize={{ mobileS: 13, tabletM: 16 }}
                    color={InspiColors.JetBlack300}
                  >
                    Postingan kamu dapat dilihat oleh seluruh partisipan di
                    forum ini, sampaikan pendapat, atau pertanyaan yang aman
                    untuk dibaca
                  </Text>
                </Box>
              </SXDescriptionContent>

              <SXDescriptionContent>
                <Box>
                  <img src="/assets/icons/bullet.svg" />
                </Box>
                <Box>
                  <Text
                    type="p"
                    fontWeight={400}
                    fontSize={{ mobileS: 13, tabletM: 16 }}
                    color={InspiColors.JetBlack300}
                  >
                    Good vibes only. Dilarang menebar komentar provokasi
                    kebencian, pornografi & SARA
                  </Text>
                </Box>
              </SXDescriptionContent>

              <SXDescriptionContent>
                <Box>
                  <img src="/assets/icons/bullet.svg" />
                </Box>
                <Box>
                  <Text
                    type="p"
                    fontWeight={400}
                    fontSize={{ mobileS: 13, tabletM: 16 }}
                    color={InspiColors.JetBlack300}
                  >
                    Be yourself! Dilarang menampilkan diri sebagai orang/entitas
                    lain dengan profil palsu
                  </Text>
                </Box>
              </SXDescriptionContent>
            </SXDescription>

            <Box mt={40} display="flex" justifyContent="center">
              <Button width={250} buttonSize="large" onClick={() => onClose()}>
                Saya mengerti
              </Button>
            </Box>
          </SXBody>
        </SXDialogPanel>
      </Dialog.Panel>
      <Overlay />
    </Dialog>
  ) : null;

  if (mounted) {
    return ReactDom.createPortal(
      modalContent,
      document.getElementById("modal-portal")
    );
  } else {
    return null;
  }
};

export default RulesModal;
