import React, { useContext } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

// Packages
import { Box, Button } from "@inspigoid/inspids-react";

// Input
import { Input } from "../../../templates/Input/Input";

// Contexts
import NotificationContext, {
  NotificationType,
} from "../../../../contexts/NotificationContext";

// Request
import Request from "../../../../redux/request";

// api
import { createPost } from "../../../../utils/apiroutelist";

// init form values
const initPostForm = {
  title: "",
  description: "",
  tags: [],
};

// validation form schema
const postFormValidation = Yup.object().shape({
  title: Yup.string()
    .min(10, "Judul pertanyaan minimal 10 karakter")
    .required("Judul pertanyaan harus diisi!"),
  description: Yup.string()
    .min(10, "Deskripsi pertanyaan minimal 10 karakter")
    .required("Deskripsi pertanyaan harus diisi!"),
  tags: Yup.array(),
});

const CreatePostForm = ({ categoryId, forumId, setRefreshPost, onClose }) => {
  const notification = useContext(NotificationContext);

  const onSubmitForm = async (formValues) => {
    const request = new Request(null, null, true);
    formValues?.tags.push(categoryId);
    formValues.forumId = forumId;

    try {
      await request.post(createPost(), formValues);
      setRefreshPost((prevState) => !prevState);
      onClose();
    } catch (error) {
      notification.open(NotificationType.Error, "Sesuatu yang salah terjadi");
    }
  };

  return (
    <Formik
      onSubmit={onSubmitForm}
      initialValues={initPostForm}
      validationSchema={postFormValidation}
    >
      {({ values: formValues, touched, errors, isValid, ...rest }) => {
        return (
          <form onSubmit={rest.handleSubmit}>
            <Input
              name="title"
              placeholder="Judul Pertanyaan"
              onChange={rest.handleChange}
              onBlur={rest.handleBlur}
              value={formValues.title}
              error={errors.title && touched.title && errors.title}
              inputOnly={true}
            />
            <Input.Textarea
              name="description"
              placeholder="Deskripsi pertanyaan"
              onChange={rest.handleChange}
              onBlur={rest.handleBlur}
              value={formValues.description}
              error={
                errors.description && touched.description && errors.description
              }
            />
            <Box
              width={"100%"}
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
            >
              <Button width={"100%"} type="submit" disabled={!isValid}>
                Kirim
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default CreatePostForm;
